import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from 'styled-components';

import { fonts, colors } from '@marvelapp/theme';
import { Box, Heading } from '@marvelapp/ui';

import Page from '../components/Page';
import PageMeta from '../components/PageMeta';

export default function DocDetailTemplate({ data, location }) {
  const post = data.mdx;
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Page location={location} title={siteTitle}>
      <PageMeta
        description={post.frontmatter.description || post.excerpt}
        title={post.frontmatter.title}
      />

      <Heading as="h1" fontSize={[6, 7]} marginTop={[6, 7]}>
        {post.frontmatter.title}
      </Heading>

      <Box paddingBottom={8}>
        <DocDetail>
          <MDXRenderer>{post.body}</MDXRenderer>
        </DocDetail>
      </Box>
    </Page>
  );
}

export const pageQuery = graphql`
  query DocDetailBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
      }
    }
  }
`;

export const DocDetail = styled.div`
  * {
    font-family: ${fonts[0]} !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    color: ${colors.licorice};
  }

  h2 {
    margin-top: 50px;
  }

  h3 {
    margin-top: 35px;
  }

  p {
    line-height: 1.6;

    a,
    a:link,
    a:visited {
      color: ${colors.marvelDark};

      &:hover {
        color: ${colors.marvel};
      }
    }

    code {
      font-size: 12px;
      background-color: ${colors.snow} !important;
      font-family: ${fonts[1]} !important;
    }
  }

  li {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  li code {
    font-size: 12px;
  }

  .gatsby-highlight {
    margin-bottom: 30px;
    border-radius: 5px;
    background-color: ${colors.snow};
    border: 1px solid ${colors.smoke};

    code {
      padding: 0;
      border-left: none !important;
      box-shadow: none !important;
      background: none !important;
    }

    pre {
      margin: 0;
      padding: 12px 16px;
      box-shadow: none !important;
      background: none !important;
      font-family: ${fonts[1]} !important;
      font-size: 13px !important;

      &:before,
      &:after {
        display: none !important;
      }
    }
  }
`;
